img.image {
  max-height: 100%;
  width: auto;
}

@media (max-width: 992px) {
  img.image.imageHiddenMobile {
    display: none;
  }
}
