.logoHeader {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-height: 100%;
  padding-right: 24px;
  padding-top: 4px;
  img {
    max-width: 100px;
    max-height: 40px;
  }
}
